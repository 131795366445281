import MartinaWangiImage from "../images/mentor/MartinaWangi.png";
import MIqbalZeaImage from "../images/mentor/MIqbalZea.png";
import AchmadNauvalImage from "../images/mentor/AchmadNauval.png";
import MArifImage from "../images/mentor/MArif.png";
import IifAhmadImage from "../images/mentor/IifAhmad.png";
import AdhiBagusImage from "../images/mentor/AdhiBagus.png";
import ArisSyukurImage from "../images/mentor/ArisSyukur.png";
import OctavianaImage from "../images/mentor/Octaviana.png";
import AmeliaRamImage from "../images/mentor/AmeliaRam.png";
import IhsanulFadilImage from "../images/mentor/IhsanulFadil.png";
import HaniyoImage from "../images/mentor/Haniyo.png";
import IgayuImage from "../images/mentor/Igayu.png";
import GemaImage from "../images/mentor/Gema.png";
import ZikriHusniImage from "../images/mentor/Zikri.png";
import RaihanImage from "../images/mentor/Raihan.png";
import NadiaImage from "../images/mentor/Nadia.png";
import AmmarImage from "../images/mentor/Amar.png";
import SilviaImage from "../images/mentor/SilivaYolanda.png";
import IftitaniaImage from "../images/mentor/Iftitania.png";
import MunawarImage from "../images/mentor/Munawar.png";
import AlzumandaniImage from "../images/mentor/Alzumandani.png";
import AgungImage from "../images/mentor/Agung.png";
import AdeAmaliaImage from "../images/mentor/AdeAmalia.png";
import GrandiesImage from "../images/mentor/Grandies.png";
import ShafiraImage from "../images/mentor/Shafira.png";
import MuzadiImage from "../images/mentor/Muzadi.png";
import AmarullahImage from "../images/mentor/Amarullah.png";

export const MentorData = {
    "type": "Mentor",
    "Collections": [
        {
            type: "Mentor",
            id: "1",
            properties:
            {
                name: "Silvia Yolanda Kristi",
                institution: "PT Pertamina (Persero)",
                occupation : "Tech Acceleration Support",
                photo: SilviaImage
            }
        },
        {
            type: "Mentor",
            id: "2",
            properties:
            {
                name: "Muhammad Fazar Alzumandani",
                institution: "DANA",
                occupation : "Risk Analyst",
                photo: AlzumandaniImage
            }
        },
        {
            type: "Mentor",
            id: "3",
            properties:
            {
                name: "Agung Adi Firdaus, S.Pd",
                institution: "Shopee",
                occupation : "Data Analyst",
                photo: AgungImage
            }
        },
        {
            type: "Mentor",
            id: "4",
            properties:
            {
                name: "Ade Amalia Putri, S.Psi",
                institution: "Kementerian Keuangan RI",
                occupation : "Org. Development Specialist",
                photo: AdeAmaliaImage
            }
        },
        {
            type: "Mentor",
            id: "5",
            properties:
            {
                name: "Shafira Vaniasari",
                institution: "PT BNI (Persero)",
                occupation : "ODP Global Analyst Program",
                photo: ShafiraImage
            }
        },
        {
            type: "Mentor",
            id: "6",
            properties:
            {
                name: "Muhamad Mahfud Muzadi, S.T.",
                institution: "APP Group - Sinarmas",
                occupation : "Management Trainee",
                photo: MuzadiImage
            }
        },
        {
            type: "Mentor",
            id: "7",
            properties:
            {
                name: "Grandies Vidriani Lismaya",
                institution: "PT BJB",
                occupation : "Customer Servicer BJB",
                photo: GrandiesImage
            }
        },
        {
            type: "Mentor",
            id: "8",
            properties:
            {
                name: "Achmad Nauval Waliuddin, S.M.",
                institution: "PT Bank Mandiri (Persero) Tbk",
                occupation : "ODP Bank Mandiri",
                photo: AchmadNauvalImage
            }
        },
        {
            type: "Mentor",
            id: "9",
            properties:
            {
                name: "M. Iqbal Zea Ulhaque, S.Pd.",
                institution: "Bank Syariah Indonesia",
                occupation : "Learning Facilitator",
                photo: MIqbalZeaImage
            }
        },
        {
            type: "Mentor",
            id: "10",
            properties:
            {
                name: "Martina Puspa Wangi, S.Gz",
                institution: "PT Nestle Indonesia",
                occupation : "Marketing Supervisor Trainee",
                photo: MartinaWangiImage
            }
        },
        {
            type: "Mentor",
            id: "11",
            properties:
            {
                name: "Muhammad Arif Rusli, S.Psi",
                institution: "Tribuana Global Group",
                occupation : "Head Office HRD",
                photo: MArifImage
            }
        },
        {
            type: "Mentor",
            id: "12",
            properties:
            {
                name: "Rayhan Ahmed Fadilla",
                institution: "Telkom Indonesia",
                occupation : "Learning Specialist",
                photo: RaihanImage
            }
        },
        {
            type: "Mentor",
            id: "13",
            properties:
            {
                name: "Adhi Bagus Nugroho",
                institution: "Perum Peruri",
                occupation : "Learning and Development",
                photo: AdhiBagusImage
            }
        },
        {
            type: "Mentor",
            id: "14",
            properties:
            {
                name: "Iif Ahmad Rifai",
                institution: "TB Global Group",
                occupation : "HR People Development",
                photo: IifAhmadImage
            }
        },
        {
            type: "Mentor",
            id: "15",
            properties:
            {
                name: "Amelia Ramadhani",
                institution: "Puyo Group",
                occupation : "Training and Development",
                photo: AmeliaRamImage
            }
        },
        {
            type: "Mentor",
            id: "16",
            properties:
            {
                name: "Nadia Nursaidatina Arifah Putri",
                institution: "Kibumi",
                occupation : "Ex Unilever and Consultant",
                photo: NadiaImage
            }
        },
        {
            type: "Mentor",
            id: "17",
            properties:
            {
                name: "Iga Yusdisti",
                institution: "TB Global Group",
                occupation : "Supervisor System Analyst",
                photo: IgayuImage
            }
        },
        {
            type: "Mentor",
            id: "18",
            properties:
            {
                name: "Haniyo Sakyamuni",
                institution: "Monash University",
                occupation : "Digital Content Learning",
                photo: HaniyoImage
            }
        },
        {
            type: "Mentor",
            id: "19",
            properties:
            {
                name: "Zikri Husni Siagian, S.T",
                institution: "PT Halmahera Persada Lygend",
                occupation : "Management Trainee",
                photo: ZikriHusniImage
            }
        },
        {
            type: "Mentor",
            id: "20",
            properties:
            {
                name: "Aris Muhamad Syukur",
                institution: "TB Global Group",
                occupation : "L&D Supervisor",
                photo: ArisSyukurImage
            }
        },
        {
            type: "Mentor",
            id: "21",
            properties:
            {
                name: "Muhammad Ihsanul Fadil",
                institution: "Corporate Innovation Asia",
                occupation : "Human Capital Officer",
                photo: IhsanulFadilImage
            }
        },
        {
            type: "Mentor",
            id: "22",
            properties:
            {
                name: "Gema Aditya Mahendra",
                institution: "Catalyzon Indonesia",
                occupation : "Project Engineering Lead",
                photo: GemaImage
            }
        },
        {
            type: "Mentor",
            id: "23",
            properties:
            {
                name: "Octaviana Sabila",
                institution: "NTT DATA",
                occupation : "Knowledge Management",
                photo: OctavianaImage
            }
        },
        {
            type: "Mentor",
            id: "24",
            properties:
            {
                name: "Ammar Jamil, S.T.",
                institution: "PT Halmahera Persada Lygend",
                occupation : "Management Trainee",
                photo: AmmarImage
            }
        },
        {
            type: "Mentor",
            id: "25",
            properties:
            {
                name: "Iftitania Ardita Putri Utami",
                institution: "Perum Perhutani",
                occupation : "Asisten Manager Research",
                photo: IftitaniaImage
            }
        },
        {
            type: "Mentor",
            id: "26",
            properties:
            {
                name: "Maulana Dzaki Munawar, S.T",
                institution: "PT Wahana Citra Nabati",
                occupation : "Production Supervisor",
                photo: MunawarImage
            }
        },
        {
            type: "Mentor",
            id: "27",
            properties:
            {
                name: "M. Farhan Amarullah, S.T.",
                institution: "PT Indonesia BTR New Energy",
                occupation : "Management Trainee",
                photo: AmarullahImage
            }
        },
    ]
};